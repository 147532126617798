// vendors
import { m as motion } from 'framer-motion';
// components
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '../components/Accordion';
import UnderlinedButton from '../components/Button/UnderlinedButton';
import InView from '../components/InView/InVew';
import Layout from '../components/Layout/Layout';
import AprilLogotype from '../components/Logo/AprilLogotype';
import AprilPictogram from '../components/Logo/AprilPictogram';
import AubergeLogotype from '../components/Logo/AubergeLogotype';
import PortableMenuText from '../components/PortableMenuText';
import Text from '../components/Text';
import Title from '../components/Title';
import VideoSection from '../components/VideoSection/VideoSection';
import petal1 from '../images/petal1.svg';
import petal2 from '../images/petal2.svg';
import petal3 from '../images/petal3.svg';
// Videos
import mp4Desktop from '../videos/april700k.mp4';
// views
import RestaurantCarousel from '../views/RestaurantCarousel/RestaurantCarousel';
import RestaurantPageAbout from '../views/RestaurantPageAbout/RestaurantPageAbout';

const alternativesLanguages = [
  {
    locale: 'fr',
    href: '/restaurant',
  },
];

function RestaurantEnPage({ data }) {
  return (
    <Layout
      className="pb-4 bg-paleOrange-50"
      alternativesLanguages={alternativesLanguages}
    >
      <div className="text-center">
        <p className="inline-block px-12 mt-32 mb-16 max-w-[32rem] md:mt-16">
          <span className="sr-only">Auberge sur Mer</span>

          <AubergeLogotype className="w-full" role="presentation" />
        </p>
      </div>

      <header className="container flex mx-auto">
        <div className="flex relative">
          <div className="grid flex-1 grid-cols-1 grid-rows-3 w-1/2">
            <h1 className="fluid-w-[8/18]">
              <span className="sr-only">April - Bisto de saison</span>

              <AprilLogotype />
            </h1>

            <InView className="mr-4 max-w-md">
              <Title level={3} className="!mb-0">
                Cuisine inspired by local and seasonal products
              </Title>

              <UnderlinedButton href="#menu">Menus ↓</UnderlinedButton>
            </InView>
          </div>

          <StaticImage
            className="flex-1 self-start w-1/2"
            src="../images/restaurant/April-Bistro-01.jpg"
            loading="eager"
          />

          <img
            src={petal1}
            role="presentation"
            alt=""
            className="absolute top-3/4 left-[40%] fluid-w-[1.625/5]"
          />

          <img
            src={petal2}
            role="presentation"
            alt=""
            className="absolute top-[20%] left-0 fluid-w-[1.25/4]"
          />

          <img
            src={petal3}
            role="presentation"
            alt=""
            className="absolute top-0 left-[40%] fluid-w-[1.625/5]"
          />
        </div>
      </header>

      <section
        id="menu"
        className="container gap-x-44 mx-auto lg:flex fluid-my-[0/8]"
      >
        <InView className="my-8 text-center lg:text-left">
          <Title level={2}>Menu</Title>
        </InView>

        <InView className="grow">
          <Accordion>
            <AccordionItem>
              <Title level={3} className="!mb-0">
                <AccordionButton>Breakfast</AccordionButton>
              </Title>

              <motion.div layout>
                <AccordionPanel layout>
                  <PortableMenuText
                    value={data.breakfastMenu?.portableText || []}
                  />
                </AccordionPanel>
              </motion.div>
            </AccordionItem>

            <AccordionItem>
              <Title level={3} className="!mb-0">
                <AccordionButton>Snacks</AccordionButton>
              </Title>

              <AccordionPanel>
                <PortableMenuText value={data.snackMenu?.portableText || []} />
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <Title level={3} className="!mb-0">
                <AccordionButton>Supper</AccordionButton>
              </Title>

              <AccordionPanel>
                <PortableMenuText value={data.dinerMenu?.portableText || []} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </InView>
      </section>

      <section className="fluid-my-[5/14]">
        <InView>
          <Title
            level={3}
            className="container mx-auto text-center fluid-my-[2/6]"
          >
            A feast for the eyes and senses
          </Title>

          <RestaurantCarousel />
        </InView>
      </section>

      <section id="a-propos" className="container mx-auto fluid-my-[5/14]">
        <InView className="fluid-my-[8/14]">
          <AprilPictogram className="mx-auto w-20 fluid-my-[2/3]" />

          <p className="mx-auto max-w-6xl font-title text-center fluid-text-[1.25/3]">
            Located in an enchanting place with a view of the river,
            April&apos;s restaurant is in the heart of one of the most beautiful
            villages in Québec.
          </p>
        </InView>

        <RestaurantPageAbout
          teamContent={
            <Text>
              <Title level={3}>The team</Title>

              <p>
                Our chef and his team will charm you with his delicious cuisine,
                inspired by seasonal products. Your taste buds will be immersed
                in flavours from our beautiful region.
              </p>
            </Text>
          }
          viewContent={
            <Text>
              <Title level={3}>The view</Title>

              <p>
                Our charming dining room with its many windows overlooks the
                stunning riverscapes of the St. Lawrence River and its equally
                amazing sunsets. You&apos;ll fall in love with the bucolic views
                of the Lower St. Lawrence region!
              </p>
            </Text>
          }
        />
      </section>

      <section
        id="horaires"
        className="container mx-auto text-center lg:flex lg:text-left fluid-my-[5/14]"
      >
        <InView className="flex-1">
          <Title className="!mb-16" level={2}>
            Opening hours
          </Title>
        </InView>

        <InView className="flex-1 space-y-16">
          <div>
            <Title className="!mb-0" level={3}>
              Supper
            </Title>

            <p className="font-title fluid-text-[0.875/2]">
              Tuesday to Saturday from 5:30 p.m. to 9:00 p.m.
              <br />
              Sunday & Monday: closed
            </p>
          </div>

          <div>
            <Title className="!mb-0" level={3}>
              Bar
            </Title>
            <p className="font-title fluid-text-[0.875/2]">
              Tuesday, wednesday and thursday from 5:00 p.m. to 11:00 p.m.
              <br />
              Friday and saturday from 3:30 p.m. to 11:00 p.m.
              <br />
              Sunday & Monday: closed
            </p>
          </div>
          
          <div>
            <Title className="!mb-0" level={3}>
              Breakfast
            </Title>

            <p className="font-title fluid-text-[0.875/2]">
              Monday to Friday from 7:30 a.m. to 11:00 a.m.
              <br />
              Saturday and Sunday from 8:00 a.m. to 12:00 a.m.
              
            </p>
          </div>
        </InView>
      </section>

      <InView className="container mx-auto fluid-my-[8/20]">
        <VideoSection desktopMp4={mp4Desktop} />
      </InView>
    </Layout>
  );
}

export default RestaurantEnPage;

RestaurantEnPage.propTypes = {
  data: PropTypes.shape({
    breakfastMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    dinerMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    snackMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query {
    breakfastMenu: sanityBreakfastMenu(_id: { eq: "breakfastMenu" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    dinerMenu: sanityDinerMenu(_id: { eq: "dinerMenu" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    snackMenu: sanitySnackMenu(_id: { eq: "snackMenu" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
  }
`;
